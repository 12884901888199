.input-wrap__input {
  width: 100%;
  max-width: 540px;
  height: 60px;
  font-size: 14px;
  line-height: 18px;
  padding: 0 32px;
  border: none;
  font-weight: 400;
  font-family: "Geometria", sans-serif;
}