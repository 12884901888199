@import '_mixins.scss';

@include font-face(Geometria, fonts/geometria/Geometria-Thin, 100, null, ttf);
@include font-face(Geometria, fonts/geometria/Geometria-ExtraLight, 200, null, ttf);
@include font-face(Geometria, fonts/geometria/Geometria-Light, 300, null, ttf);
@include font-face(Geometria, fonts/geometria/Geometria, 400, null, ttf);
@include font-face(Geometria, fonts/geometria/Geometria-Medium, 500, null, ttf);
@include font-face(Geometria, fonts/geometria/Geometria-Bold, 600, null, ttf);
@include font-face(Geometria, fonts/geometria/Geometria-ExtraBold, 700, null, ttf);
@include font-face(Geometria, fonts/geometria/Geometria-Heavy, 800, null, ttf);

@include font-face(TTFirsNeue, fonts/firsneue/TTFirsNeue-Thin, 100, null, ttf);
@include font-face(TTFirsNeue, fonts/firsneue/TTFirsNeue-ExtraLight, 200, null, ttf);
@include font-face(TTFirsNeue, fonts/firsneue/TTFirsNeue-Light, 300, null, ttf);
@include font-face(TTFirsNeue, fonts/firsneue/TTFirsNeue-Regular, 400, null, ttf);
@include font-face(TTFirsNeue, fonts/firsneue/TTFirsNeue-Medium, 500, null, ttf);
@include font-face(TTFirsNeue, fonts/firsneue/TTFirsNeue-Bold, 600, null, ttf);
@include font-face(TTFirsNeue, fonts/firsneue/TTFirsNeue-ExtraBold, 700, null, ttf);