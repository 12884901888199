.advantages {
  padding: 120px 0 80px 0;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .advantages {
    padding: 110px 0 70px 0;
  }
}
.advantages img {
  max-width: 462px;
  display: block;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .advantages img {
    max-width: 360px;
  }
}
.advantages .container {
  flex-direction: column;
}
.advantages h2 {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .advantages h2 {
    margin-bottom: 50px;
  }
}
.advantages__wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 80px;
}
@media (max-width: 768px) {
  .advantages__wrap {
    flex-direction: column;
    align-items: center;
  }
}
.advantages .icon {
  position: absolute;
  fill: transparent;
}
.advantages .icon.arrow {
  width: 40px;
  height: 67px;
  stroke: #272F35;
  top: -35px;
  left: -15px;
}
.advantages .icon.flash {
  stroke: #029DF7;
  width: 41px;
  height: 86px;
  right: -30px;
  bottom: -25px;
}
.advantages__element {
  max-width: 350px;
  background: #f5f5f5;
  padding: 37px;
  position: relative;
}
@media (max-width: 960px) {
  .advantages__element {
    font-size: 30px;
    max-width: 250px;
  }
}
@media (max-width: 768px) {
  .advantages__element {
    max-width: 410px;
    margin-bottom: 30px;
  }
  .advantages__element:last-child {
    margin-bottom: 0;
  }
}
.advantages__element h3 {
  font-size: 22px;
  line-height: 28px;
  color: #029DF7;
}
@media (max-width: 768px) {
  .advantages__element h3 {
    font-size: 20px;
    line-height: 26px;
  }
}
.advantages__element p {
  margin-top: 10px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .advantages__element p {
    font-size: 16px;
    line-height: 20px;
  }
}