.footer {
  padding: 120px 0 0 0;
}
@media (max-width: 768px) {
  .footer {
    padding-top: 100px;
  }
}
@media (max-width: 480px) {
  .footer {
    padding-top: 100px;
  }
}
.footer .container {
  max-width: 780px;
  flex-direction: column;
}
@media (max-width: 960px) {
  .footer .container {
    max-width: 820px;
  }
}
@media (max-width: 768px) {
  .footer .container {
    max-width: 640px;
  }
}
@media (max-width: 768px) {
  .footer .container {
    max-width: 345px;
  }
}
.footer h2 {
  margin-bottom: 50px;
}
.footer__wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 130px;
}
@media (max-width: 768px) {
  .footer__wrap {
    margin-bottom: 85px;
  }
}
@media (max-width: 480px) {
  .footer__wrap {
    flex-direction: column;
  }
}
.footer .icon {
  fill: #029DF7;
  margin-right: 13px;
  height: 32px;
}
@media (max-width: 480px) {
  .footer .icon {
    margin-bottom: 7px;
    margin-right: 0;
  }
}
.footer .icon-tg {
  width: 39px;
}
.footer .icon-phone {
  width: 23px;
}
.footer .icon-mail {
  width: 40px;
}
.footer__element {
  display: flex;
  align-items: center;
  color: #029DF7;
  transition: 0.15s ease-in-out;
}
@media (max-width: 480px) {
  .footer__element {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .footer__element:last-child {
    margin-bottom: 0;
  }
}
.footer__element:hover {
  opacity: 0.8;
}
.footer__politics {
  background: #029DF7;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__politics a {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  border-bottom: 1px solid transparent;
  transition: 0.15s ease-in-out;
}
@media (max-width: 480px) {
  .footer__politics a {
    font-size: 20px;
  }
}
.footer__politics a:hover {
  border-bottom-color: #fff;
}