@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.benefit {
    padding: 120px 0;

    @include landscape {
        padding: 110px 0;
    }

    .container {
        flex-direction: column;
        max-width: 1110px;

        @include landscape {
            max-width: 820px;
        }
    }

    h2 {
        max-width: 730px;
        margin: 0 auto;

        @include landscape {
            max-width: 530px;
        }

        span {
            color: $blue;
        }
    }
    &__wrap {
        margin-top: 70px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;

        @include landscape {
            margin-top: 50px;
        }
    }

    .icon {
        fill: transparent;
        stroke: $black;
        position: absolute;
        left: 0;

        @include landscape {
            position: relative;
            margin-bottom: 30px;
        }

        @include landscape {
            position: absolute;
        }

        &-flash {
            width: 41px;
            height: 64px;
        }

        &-person {
            width: 47px;
            height: 60px;
        }

        &-time {
            width: 33px;
            height: 56px;
        }

        &-flash-arrow {
            width: 50px;
            height: 56px;
        }
    }

    &__element {
        max-width: 520px;
        margin-bottom: 60px;
        padding-left: 70px;
        position: relative;

        @include landscape {
            padding: 0;
            max-width: 390px;
        }

        @include landscape {
            max-width: 100%;
            padding-left: 70px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        h3 {
            color: $blue;
            margin-bottom: 10px;
            font-size: 22px;
            line-height: 28px;

            @include landscape {
                font-size: 20px;
                line-height: 26px;
            }
        }

        p {
            font-size: 18px;
            line-height: 23px;
            font-weight: 500;

            @include landscape {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}