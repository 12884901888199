$black: #272F35;
$white: #fff;
$blue: #029DF7;
$lightBlue: #39FFF3;
$lightGrey: #f5f5f5;

// breakpoints
$mobile: 480px;
$portrait: 768px;
$landscape: 960px;
$desktop: 1280px;