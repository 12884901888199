.staff {
  padding: 120px 0 134px 0;
  background: #029DF7 url(../images/staffbg.png) no-repeat bottom center;
  background-size: cover;
  color: #fff;
  position: relative;
  overflow-x: hidden;
  z-index: 1;
}
@media (max-width: 768px) {
  .staff {
    padding: 110px 0;
  }
}
@media (max-width: 480px) {
  .staff {
    padding: 100px 0;
  }
}
.staff:before {
  content: "";
  position: absolute;
  pointer-events: none;
  width: 1075px;
  background: url(../images/staffbg2.svg);
  height: 422px;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
@media (max-width: 960px) {
  .staff:before {
    background: url(../images/staffbg2_768.svg);
    width: 100%;
    height: 467px;
    background-repeat: no-repeat;
    top: 185px;
  }
}
@media (max-width: 768px) {
  .staff:before {
    background: url(../images/staffbg2_720.svg);
    width: 100%;
    height: 547px;
    background-repeat: no-repeat;
    top: 130px;
    background-position: 20px 0;
  }
}
@media (max-width: 480px) {
  .staff:before {
    background: url(../images/staffbg2_480.svg);
    height: 767px;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    transform: translateX(0);
    background-position: -35px 10px;
  }
}
.staff .container {
  flex-direction: column;
}
.staff h2 {
  color: #fff;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .staff h2 {
    margin-bottom: 15px;
  }
}
.staff h3 {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 23px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .staff h3 {
    font-size: 16px;
    line-height: 20px;
    max-width: 353px;
  }
}
.staff__form {
  max-width: 540px;
  margin: 60px auto 0 auto;
  width: 100%;
  display: block;
}
@media (max-width: 768px) {
  .staff__form {
    max-width: 410px;
  }
}
.staff__form input {
  margin-bottom: 15px;
}
.staff__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}
.staff .button {
  max-width: 167px;
  margin-right: 14px;
}
@media (max-width: 768px) {
  .staff .button {
    width: 117px;
  }
}
.staff .button:before {
  width: 167px;
}
@media (max-width: 768px) {
  .staff .button:before {
    width: 117px;
  }
}
.staff__personals {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  max-width: 230px;
}
@media (max-width: 768px) {
  .staff__personals {
    font-size: 12px;
    max-width: 200px;
  }
}
.staff__personals a {
  color: #fff;
  transition: 0.15s ease-in-out;
  border-bottom: 1px solid #fff;
}
.staff__personals a:hover {
  border-bottom-color: transparent;
}