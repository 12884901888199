.howitswork {
  background: #f5f5f5;
  padding: 120px 0;
}
@media (max-width: 768px) {
  .howitswork {
    padding: 110px 0;
  }
}
.howitswork .container {
  flex-direction: column;
}
.howitswork h2 {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .howitswork h2 {
    margin-bottom: 30px;
  }
}
.howitswork__wrap {
  display: flex;
  justify-content: space-between;
  font-family: "TTFirsNeue", sans-serif;
}
@media (max-width: 960px) {
  .howitswork__wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.howitswork__num {
  width: 65px;
  line-height: 123px;
  -webkit-text-stroke: 4px #029DF7;
  font-size: 95px;
  letter-spacing: 0.02em;
  color: transparent;
  font-weight: 500;
}
@media (max-width: 960px) {
  .howitswork__num {
    min-width: 65px;
    display: block;
    margin-right: 29px;
    text-align: center;
  }
}
.howitswork p {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}
.howitswork p span {
  color: #029DF7;
}
.howitswork__element {
  max-width: 255px;
}
@media (max-width: 960px) {
  .howitswork__element {
    max-width: 390px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}