html {
  font-size: 16px;
}

*, *::before, *::after {
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@font-face {
  font-family: "Geometria";
  font-weight: 100;
  src: url("fonts/geometria/Geometria-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Geometria";
  font-weight: 200;
  src: url("fonts/geometria/Geometria-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Geometria";
  font-weight: 300;
  src: url("fonts/geometria/Geometria-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Geometria";
  font-weight: 400;
  src: url("fonts/geometria/Geometria.ttf") format("truetype");
}
@font-face {
  font-family: "Geometria";
  font-weight: 500;
  src: url("fonts/geometria/Geometria-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Geometria";
  font-weight: 600;
  src: url("fonts/geometria/Geometria-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Geometria";
  font-weight: 700;
  src: url("fonts/geometria/Geometria-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Geometria";
  font-weight: 800;
  src: url("fonts/geometria/Geometria-Heavy.ttf") format("truetype");
}
@font-face {
  font-family: "TTFirsNeue";
  font-weight: 100;
  src: url("fonts/firsneue/TTFirsNeue-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "TTFirsNeue";
  font-weight: 200;
  src: url("fonts/firsneue/TTFirsNeue-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "TTFirsNeue";
  font-weight: 300;
  src: url("fonts/firsneue/TTFirsNeue-Light.ttf") format("truetype");
}
@font-face {
  font-family: "TTFirsNeue";
  font-weight: 400;
  src: url("fonts/firsneue/TTFirsNeue-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "TTFirsNeue";
  font-weight: 500;
  src: url("fonts/firsneue/TTFirsNeue-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "TTFirsNeue";
  font-weight: 600;
  src: url("fonts/firsneue/TTFirsNeue-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "TTFirsNeue";
  font-weight: 700;
  src: url("fonts/firsneue/TTFirsNeue-ExtraBold.ttf") format("truetype");
}
body {
  font-family: "Geometria", sans-serif;
  font-weight: 500;
  color: #272F35;
  overflow-x: hidden;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  padding: 0 15px;
}
@media (max-width: 960px) {
  .container {
    max-width: 820px;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 640px;
  }
}

h1, h2, h3, h4 {
  font-family: "TTFirsNeue", sans-serif;
  font-weight: 500;
}

h2 {
  font-size: 40px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #272F35;
  width: 100%;
  text-align: center;
}
@media (max-width: 960px) {
  h2 {
    font-size: 30px;
    line-height: 39px;
  }
}
@media (max-width: 768px) {
  h2 {
    font-size: 30px;
    line-height: 39px;
  }
}