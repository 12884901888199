@import 'helpers/_variables.scss';
@import 'helpers/_mixins.scss';
@import 'helpers/_reset.scss';
@import 'helpers/_animate.scss';
@import 'helpers/_fonts.scss';

body {
	font-family: 'Geometria', sans-serif;
	font-weight: 500;
	color: $black;
	overflow-x: hidden;
}

.container {
	max-width: 1140px;
	margin: 0 auto;
	display: flex;
	width: 100%;
	padding: 0 15px;

	@include landscape {
		max-width: 820px;
	}

	@include portrait {
		max-width: 640px;
	}
}

h1, h2, h3, h4 {
	font-family: 'TTFirsNeue', sans-serif;
	font-weight: 500;
}

h2 {
	font-size: 40px;
	line-height: 42px;
	letter-spacing: 0.02em;
	color: $black;
	width: 100%;
	text-align: center;

	@include landscape {
		font-size: 30px;
		line-height: 39px;
	}

	@include portrait {
		font-size: 30px;
		line-height: 39px;
	}
}