.header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
}
.header .container {
  height: 84px;
  align-items: center;
}
.header__logo {
  margin-right: auto;
  font-size: 0;
}
.header .icon {
  width: 38px;
  height: 32px;
  fill: #029DF7;
  transition: 0.15s ease-in-out;
}
.header .icon:hover {
  opacity: 0.8;
}
.header__burger {
  margin-left: 36px;
  display: none;
}
.header__burger .icon {
  width: 40px;
  height: 20px;
  stroke: #272F35;
}
@media (max-width: 960px) {
  .header__burger {
    display: block;
  }
}
.header__nav {
  display: flex;
  margin-right: 17px;
  position: relative;
  z-index: 7;
}
@media (max-width: 960px) {
  .header__nav {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -17px;
    height: 100vh;
    width: 355px;
    background: #fff;
    flex-direction: column;
    padding: 31px;
    z-index: 20;
    visibility: hidden;
    opacity: 0;
    transition: 0.15s ease-in-out;
    pointer-events: none;
  }
  .header__nav.show {
    visibility: visible;
    opacity: 1;
    pointer-events: unset;
    transition: 0.15s ease-in-out;
  }
}
.header__nav li {
  margin-right: 40px;
}
@media (max-width: 960px) {
  .header__nav li {
    margin-bottom: 28px;
    margin-right: 0;
  }
  .header__nav li.header__mob-logo {
    margin-bottom: 98px;
  }
}
.header__nav a {
  text-transform: uppercase;
  font-size: 14px;
  color: #272F35;
  font-weight: 500;
  transition: 0.15s ease-in-out;
  letter-spacing: 0.02em;
}
@media (max-width: 960px) {
  .header__nav a {
    font-size: 16px;
    line-height: 20px;
  }
}
.header__nav a:hover {
  color: #029DF7;
}
.header__mob-logo, .header__mob-tg, .header__close {
  display: none;
}
@media (max-width: 960px) {
  .header__mob-logo, .header__mob-tg, .header__close {
    display: block;
  }
  .header__mob-logo {
    width: 140px;
  }
  .header__mob-tg {
    margin-top: auto;
  }
  .header .icon-cross {
    stroke: #272F35;
    width: 24px;
    height: 24px;
  }
  .header__close {
    position: absolute;
    padding: 20px;
    top: 11px;
    right: 55px;
  }
}
.header__overlay {
  z-index: 6;
  background: rgba(2, 157, 247, 0.5);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.15s ease-in-out;
  pointer-events: none;
}
.header__overlay.show {
  visibility: visible;
  opacity: 1;
  pointer-events: unset;
  transition: 0.15s ease-in-out;
}