html {
	font-size: 16px;
}

*, *::before, *::after {
	box-sizing: border-box;
}

*:focus {
	outline: 0;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
	margin: 0;
	padding: 0;
	font-weight: 400;
}

ol, ul {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	text-decoration: none;
}
