@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.findwork {
    padding: 120px 0 134px 0;
    background: $black url(../images/workbg.png) no-repeat bottom center;
    background-size: cover;
    color: $white;
    position: relative;
    overflow-x: hidden;
    z-index: 1;
    
	@include portrait {
        padding: 110px 0;
	}

    &:before {
        content: '';
        position: absolute;
        pointer-events: none;
        width: 1194px;
        background: url(../images/workbg2.svg);
        height: 421px;
        top: 105px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        @include landscape {
            background: url(../images/workbg2_768.svg);
            width: 100%;
            height: 500px;
            top: 32px;
            background-repeat: no-repeat;
            background-position: 24px 0;
        }

        @include portrait {
            background: url(../images/workbg2_720.svg);
            width: 100%;
            height: 567px;
            background-repeat: no-repeat;
            top: 43px;
            background-position: 20px 0;
        }

        @include mobile {
            background: url(../images/workbg2_480.svg);
            width: 100%;
            height: 657px;
            background-repeat: no-repeat;
            top: 0;
            background-position: 60px 0;
        }
    }

    .container {
        flex-direction: column;
    }

    h2 {
        color: $white;
        margin-bottom: 10px;

        @include portrait {
            margin-bottom: 15px;
        }
    }
    h3 {
        width: 100%;
        text-align: center;
        color: $white;
        font-weight: 18px;
        line-height: 23px;
        margin: 0 auto;

        @include portrait {
            font-size: 16px;
            line-height: 20px;
            max-width: 353px;
        }
    }

    &__form {
        max-width: 540px;
        margin: 60px auto 0 auto;
        width: 100%;
        display: block;

        @include portrait {
            max-width: 410px;
        }

        input {
            margin-bottom: 15px;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 25px;
    }

    .button {
        max-width: 167px;
        margin-right: 14px;

        @include portrait {
            width: 117px;
        }

        &:before {
            width: 167px;

            @include portrait {
                width: 117px;
            }
        }
    }

    &__personals {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        max-width: 230px;

        @include portrait {
            font-size: 12px;
            max-width: 200px;
        }

        a {
            color: $white;
            @include transition(.15s ease-in-out);
            border-bottom: 1px solid $white;

            &:hover {
                border-bottom-color: transparent;
            }
        }
    }
}