// stylelint-disable
// миксин transition - Использование:   @include transition(width, height, 0.3s ease-in-out);
@mixin transition($transitions...) {
	$unfoldtransition: ();

	@each $transition in $transitions {
		$unfoldtransition: append($unfoldtransition, unfoldtransition($transition), comma);
	}

	transition: $unfoldtransition;
}

@function unfoldtransition ($transition) {
	$property: all;
	$duration: .2s;
	$easing: null;
	$delay: null;
	$defaultProperties: ($property, $duration, $easing, $delay);
	$unfoldtransition: ();

	@for $i from 1 through length($defaultProperties) {
		$p: null;

		@if $i <= length($transition) {
			$p: nth($transition, $i);
		} @else {
			$p: nth($defaultProperties, $i);
		}

		$unfoldtransition: append($unfoldtransition, $p);
	}

	@return $unfoldtransition;
}

// font-face mixin
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: '?',
		svg: '#' + str-replace($name, ' ', '_')
	);

	$formats: (
		otf: 'opentype',
		ttf: 'truetype'
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@mixin mobile {
	@media (max-width: $mobile) {
		@content;
	}
}

@mixin portrait {
	@media (max-width: $portrait) {
		@content;
	}
}

@mixin landscape {
	@media (max-width: $landscape) {
		@content;
	}
}

@mixin desktop {
	@media (max-width: $desktop) {
		@content;
	}
}
// stylelint-enable