@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 30;
    visibility: hidden;
    opacity: 0;
    @include transition(.15s ease-in-out);

    &.show {
        visibility: visible;
        opacity: 1;
        pointer-events: unset;
        @include transition(.15s ease-in-out);
    }

    .icon {
        stroke: $black;
        width: 64px;
        height: 64px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        padding: 20px;
    }

    &__overlay {
        background: rgba($blue, .5);
        width: 100vw;
        height: 100vh;
    }

    &__window {
        padding: 85px 130px;
        background: $white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(calc(-50% + 0.5px), calc(-50% + 0.5px));
        max-width: 920px;
        width: 100%;

        @include portrait {
            padding: 40px 10px;
            max-width: 630px;
        }
    }
    h2 {
        color: $black;
        margin-bottom: 23px;
    }
    h3 {
        width: 100%;
        text-align: center;
        color: $blue;
        font-weight: 18px;
        line-height: 23px;
    }

    &__form {
        max-width: 540px;
        margin: 60px auto 0 auto;
        width: 100%;
        display: block;

        @include portrait {
            max-width: 410px;
        }

        input {
            margin-bottom: 15px;
            background: $lightGrey;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 25px;
    }

    .button {
        max-width: 167px;
        margin-right: 14px;

        @include portrait {
            width: 117px;
        }

        &:before {
            width: 167px;

            @include portrait {
                width: 117px;
            }
        }
    }

    &__personals {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        max-width: 230px;

        @include portrait {
            font-size: 12px;
            max-width: 200px;
        }

        a {
            color: $black;
            @include transition(.15s ease-in-out);
            border-bottom: 1px solid $black;

            &:hover {
                border-bottom-color: transparent;
            }
        }
    }
}