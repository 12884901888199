@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.advantages {
    padding: 120px 0 80px 0;
    overflow-x: hidden;
    

	@include portrait {
        padding: 110px 0 70px 0;
	}

    img {
        max-width: 462px;
        display: block;
        margin: 0 auto;

        @include portrait {
            max-width: 360px;
        }
    }

    .container {
        flex-direction: column;
    }

    

    h2 {
        margin-bottom: 60px;
    
        @include portrait {
            margin-bottom: 50px;
        }
    }

    &__wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 80px;
        
        @include portrait {
            flex-direction: column;
            align-items: center;
        }
    }

    .icon {
        position: absolute;
        fill: transparent;

        &.arrow {
            width: 40px;
            height: 67px;
            stroke: $black;
            top: -35px;
            left: -15px;
        }

        &.flash {
            stroke: $blue;
            width: 41px;
            height: 86px;
            right: -30px;;
            bottom: -25px;
        }
    }
    

    &__element {
        max-width: 350px;
        background: $lightGrey;
        padding: 37px;
        position: relative;

        @include landscape {
            font-size: 30px;
            max-width: 250px;
        }
        
        @include portrait {
            max-width: 410px;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        h3 {
            font-size: 22px;
            line-height: 28px;
            color: $blue;
        
            @include portrait {
                font-size: 20px;
                line-height: 26px;
            }
        }

        p {
            margin-top: 10px;
            font-size: 18px;
            line-height: 23px;
            font-weight: 500;
        
            @include portrait {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}