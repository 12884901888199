@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.howitswork {
    background: $lightGrey;
    padding: 120px 0;
    
	@include portrait {
        padding: 110px 0;
	}

    .container {
        flex-direction: column;
    }

    h2 {
        margin-bottom: 60px;
    
        @include portrait {
            margin-bottom: 30px;
        }
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        font-family: 'TTFirsNeue', sans-serif;

        @include landscape {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__num {
        width: 65px;
        line-height: 123px;
        -webkit-text-stroke: 4px $blue;
        font-size: 95px;
        letter-spacing: 0.02em;
        color: transparent;
        font-weight: 500;

        @include landscape {
            min-width: 65px;
            display: block;
            margin-right: 29px;
            text-align: center;
        }
    }

    p {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;

        span {
            color: $blue;
        }
    }

    &__element {
        max-width: 255px;

        @include landscape {
            max-width: 390px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
}