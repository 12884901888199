.button {
  max-width: 227px;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  background: #fff;
  position: relative;
  font-family: "Geometria", sans-serif;
  border-radius: 2px;
  letter-spacing: 0.04em;
  z-index: 2;
}
@media (max-width: 768px) {
  .button {
    font-size: 13px;
    height: 46px;
  }
}
.button:before {
  content: "";
  position: absolute;
  width: 227px;
  height: 56px;
  border: 4px solid #fff;
  right: -14px;
  bottom: -14px;
  transition: 0.15s ease-in-out;
  pointer-events: none;
  border-radius: 2px;
  z-index: -1;
}
@media (max-width: 768px) {
  .button:before {
    height: 46px;
  }
}
.button:hover:before {
  right: 0;
  bottom: 0;
}
.button.dark {
  background: #272F35;
  color: #fff;
}
.button.dark:before {
  border-color: #272F35;
}
.button.blue {
  background: #029DF7;
  color: #fff;
}
.button.blue:before {
  border-color: #029DF7;
}