.main {
  padding: 233px 0 230px 0;
  background: #029DF7 url(../images/mainbg.svg) 0 56px no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .main {
    padding: 150px 0 175px 0;
  }
}
@media (max-width: 480px) {
  .main {
    padding: 162px 0 370px 0;
  }
}
.main:before {
  content: "";
  position: absolute;
  pointer-events: none;
  background-image: url(../images/mainbg2.png);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0 auto;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  max-width: 1440px;
  width: 100%;
}
@media (max-width: 960px) {
  .main:before {
    background-image: url(../images/mainbg2_768.png);
  }
}
@media (max-width: 768px) {
  .main:before {
    background-image: url(../images/mainbg2_720.png);
  }
}
@media (max-width: 480px) {
  .main:before {
    background-image: url(../images/mainbg2_480.png);
    background-position: top center;
    background-size: 90%;
  }
}
.main .container {
  flex-direction: column;
  align-items: center;
}
.main__heading {
  font-size: 86px;
  line-height: 112px;
  letter-spacing: 0.02em;
  text-align: center;
  font-weight: 500;
}
@media (max-width: 960px) {
  .main__heading {
    font-size: 60px;
  }
}
@media (max-width: 768px) {
  .main__heading {
    display: block;
    line-height: 69px;
  }
}
@media (max-width: 480px) {
  .main__heading {
    font-size: 46px;
    line-height: 53px;
  }
}
.main__heading span {
  color: #39FFF3;
}
@media (max-width: 768px) {
  .main__heading span {
    display: block;
  }
}
.main__subtext {
  color: #fff;
  letter-spacing: 0.02em;
  line-height: 31px;
  font-size: 24px;
  margin-top: 5px;
  max-width: 900px;
  text-align: center;
}
@media (max-width: 960px) {
  .main__subtext {
    font-size: 20px;
    margin-top: 4px;
  }
  .main__subtext br {
    display: none;
  }
}
@media (max-width: 768px) {
  .main__subtext {
    max-width: 557px;
    margin-top: 35px;
  }
}
@media (max-width: 480px) {
  .main__subtext {
    max-width: 316px;
    margin: 0 auto;
    margin-top: 11px;
  }
}
.main .button {
  margin-top: 71px;
  margin-right: 14px;
}
@media (max-width: 768px) {
  .main .button {
    margin-top: 51px;
  }
}
.main-desc {
  background: #272F35;
  color: #fff;
  font-size: 32px;
  letter-spacing: 0.02em;
  max-width: 730px;
  margin: 0 auto;
  text-align: center;
  line-height: 41px;
  height: 238px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 90px;
  margin-top: -105px;
  z-index: 2;
  position: relative;
  border-radius: 2px;
}
@media (max-width: 960px) {
  .main-desc {
    max-width: 530px;
    font-size: 30px;
    padding: 0 70px;
  }
}
@media (max-width: 768px) {
  .main-desc {
    font-size: 28px;
    width: 450px;
    line-height: 35px;
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .main-desc {
    font-size: 20px;
    line-height: 26px;
  }
}