@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.header {
    position: sticky;
    top: 0;
    z-index: 10;
    background: rgba($white, .9);

    .container {
        height: 84px;
        align-items: center;
    }

    &__logo {
        margin-right: auto;
        font-size: 0;
    }

    .icon {
        width: 38px;
        height: 32px;
        fill: $blue;
        @include transition(.15s ease-in-out);

        &:hover {
            opacity: 0.8;
        }
    }

    &__burger {
        margin-left: 36px;
        display: none;

        .icon {
            width: 40px;
            height: 20px;
            stroke: $black;
        }

        @include landscape {
            display: block;
        }
    }

    &__nav{
        display: flex;
        margin-right: 17px;
        position: relative;
        z-index: 7;

        @include landscape {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -17px;
            height: 100vh;
            width: 355px;
            background: $white;
            flex-direction: column;
            padding: 31px;
            z-index: 20;
            visibility: hidden;
            opacity: 0;
            @include transition(.15s ease-in-out);
            pointer-events: none;

            &.show {
                visibility: visible;
                opacity: 1;
                pointer-events: unset;
                @include transition(.15s ease-in-out);
            }
        }

        li {
            margin-right: 40px;

            @include landscape {
                margin-bottom: 28px;
                margin-right: 0;

                &.header__mob-logo {
                    margin-bottom: 98px;
                }
            }
        }

        a {
            text-transform: uppercase;
            font-size: 14px;
            color: $black;
            font-weight: 500;
            @include transition(.15s ease-in-out);
            letter-spacing: 0.02em;

            @include landscape {
                font-size: 16px;
                line-height: 20px;
            }

            &:hover {
                color: $blue;
            }
        }
    }
    
    &__mob-logo, &__mob-tg, &__close {
        display: none;
    }

    @include landscape {

        &__mob-logo, &__mob-tg, &__close {
            display: block;
        }
        &__mob-logo {
            width: 140px;
        }

        &__mob-tg {
            margin-top: auto;
        }

        .icon-cross {
            stroke: $black;
            width: 24px;
            height: 24px;
        }

        &__close {
            position: absolute;
            padding: 20px;
            top: 11px;
            right: 55px;
        }
    }

    &__overlay {
        z-index: 6;
        background: rgba($blue, .5);
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        @include transition(.15s ease-in-out);
        pointer-events: none;

        &.show {
            visibility: visible;
            opacity: 1;
            pointer-events: unset;
            @include transition(.15s ease-in-out);
        }
    }
}