@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.main {
    padding: 233px 0 230px 0;
    background: $blue url(../images/mainbg.svg) 0 56px no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;

    @include portrait {
        padding: 150px 0 175px 0;
    }

    @include mobile {
        padding: 162px 0 370px 0;
    }

    &:before {
        content: '';
        position: absolute;
        pointer-events: none;
        background-image: url(../images/mainbg2.png);
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: 0 auto;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        max-width: 1440px;
        width: 100%;

        @include landscape {
            background-image: url(../images/mainbg2_768.png);
        }

        @include portrait {
            background-image: url(../images/mainbg2_720.png);
        }

        @include mobile {
            background-image: url(../images/mainbg2_480.png);
            background-position: top center;
            background-size: 90%;
        }
    }

    .container {
        flex-direction: column;
        align-items: center;
    }

    &__heading {
        font-size: 86px;
        line-height: 112px;
        letter-spacing: 0.02em;
        text-align: center;
        font-weight: 500;

        @include landscape {
            font-size: 60px;
        }

        @include portrait {
            display: block;
            line-height: 69px;
        }

        @include mobile {
            font-size: 46px;
            line-height: 53px;
        }

        span {
            color: $lightBlue;

            @include portrait {
                display: block;
            }
        }
    }

    &__subtext {
        color: $white;
        letter-spacing: 0.02em;
        line-height: 31px;
        font-size: 24px;
        margin-top: 5px;
        max-width: 900px;
        text-align: center;

        @include landscape {
            font-size: 20px;
            margin-top: 4px;

            br {
                display: none;
            }
        }

        @include portrait {
            max-width: 557px;
            margin-top: 35px;
        }

        @include mobile {
            max-width: 316px;
            margin: 0 auto;
            margin-top: 11px;
        }
    }

    .button {
        margin-top: 71px;
        margin-right: 14px;

        @include portrait {
            margin-top: 51px;
        }
    }

    &-desc {
        background: $black;
        color: $white;
        font-size: 32px;
        letter-spacing: 0.02em;
        max-width: 730px;
        margin: 0 auto;
        text-align: center;
        line-height: 41px;
        height: 238px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 90px;
        margin-top: -105px;
        z-index: 2;
        position: relative;
        border-radius: 2px;

        @include landscape {
            max-width: 530px;
            font-size: 30px;
            padding: 0 70px;
        }

        @include portrait {
            font-size: 28px;
            width: 450px;
            line-height: 35px;
            padding: 0 40px;
        }

        @include portrait {
            font-size: 20px;
            line-height: 26px;
        }
    }
}