.findwork {
  padding: 120px 0 134px 0;
  background: #272F35 url(../images/workbg.png) no-repeat bottom center;
  background-size: cover;
  color: #fff;
  position: relative;
  overflow-x: hidden;
  z-index: 1;
}
@media (max-width: 768px) {
  .findwork {
    padding: 110px 0;
  }
}
.findwork:before {
  content: "";
  position: absolute;
  pointer-events: none;
  width: 1194px;
  background: url(../images/workbg2.svg);
  height: 421px;
  top: 105px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
@media (max-width: 960px) {
  .findwork:before {
    background: url(../images/workbg2_768.svg);
    width: 100%;
    height: 500px;
    top: 32px;
    background-repeat: no-repeat;
    background-position: 24px 0;
  }
}
@media (max-width: 768px) {
  .findwork:before {
    background: url(../images/workbg2_720.svg);
    width: 100%;
    height: 567px;
    background-repeat: no-repeat;
    top: 43px;
    background-position: 20px 0;
  }
}
@media (max-width: 480px) {
  .findwork:before {
    background: url(../images/workbg2_480.svg);
    width: 100%;
    height: 657px;
    background-repeat: no-repeat;
    top: 0;
    background-position: 60px 0;
  }
}
.findwork .container {
  flex-direction: column;
}
.findwork h2 {
  color: #fff;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .findwork h2 {
    margin-bottom: 15px;
  }
}
.findwork h3 {
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: 18px;
  line-height: 23px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .findwork h3 {
    font-size: 16px;
    line-height: 20px;
    max-width: 353px;
  }
}
.findwork__form {
  max-width: 540px;
  margin: 60px auto 0 auto;
  width: 100%;
  display: block;
}
@media (max-width: 768px) {
  .findwork__form {
    max-width: 410px;
  }
}
.findwork__form input {
  margin-bottom: 15px;
}
.findwork__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}
.findwork .button {
  max-width: 167px;
  margin-right: 14px;
}
@media (max-width: 768px) {
  .findwork .button {
    width: 117px;
  }
}
.findwork .button:before {
  width: 167px;
}
@media (max-width: 768px) {
  .findwork .button:before {
    width: 117px;
  }
}
.findwork__personals {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  max-width: 230px;
}
@media (max-width: 768px) {
  .findwork__personals {
    font-size: 12px;
    max-width: 200px;
  }
}
.findwork__personals a {
  color: #fff;
  transition: 0.15s ease-in-out;
  border-bottom: 1px solid #fff;
}
.findwork__personals a:hover {
  border-bottom-color: transparent;
}