.benefit {
  padding: 120px 0;
}
@media (max-width: 960px) {
  .benefit {
    padding: 110px 0;
  }
}
.benefit .container {
  flex-direction: column;
  max-width: 1110px;
}
@media (max-width: 960px) {
  .benefit .container {
    max-width: 820px;
  }
}
.benefit h2 {
  max-width: 730px;
  margin: 0 auto;
}
@media (max-width: 960px) {
  .benefit h2 {
    max-width: 530px;
  }
}
.benefit h2 span {
  color: #029DF7;
}
.benefit__wrap {
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 960px) {
  .benefit__wrap {
    margin-top: 50px;
  }
}
.benefit .icon {
  fill: transparent;
  stroke: #272F35;
  position: absolute;
  left: 0;
}
@media (max-width: 960px) {
  .benefit .icon {
    position: relative;
    margin-bottom: 30px;
  }
}
@media (max-width: 960px) {
  .benefit .icon {
    position: absolute;
  }
}
.benefit .icon-flash {
  width: 41px;
  height: 64px;
}
.benefit .icon-person {
  width: 47px;
  height: 60px;
}
.benefit .icon-time {
  width: 33px;
  height: 56px;
}
.benefit .icon-flash-arrow {
  width: 50px;
  height: 56px;
}
.benefit__element {
  max-width: 520px;
  margin-bottom: 60px;
  padding-left: 70px;
  position: relative;
}
@media (max-width: 960px) {
  .benefit__element {
    padding: 0;
    max-width: 390px;
  }
}
@media (max-width: 960px) {
  .benefit__element {
    max-width: 100%;
    padding-left: 70px;
  }
  .benefit__element:last-child {
    margin-bottom: 0;
  }
}
.benefit__element h3 {
  color: #029DF7;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 28px;
}
@media (max-width: 960px) {
  .benefit__element h3 {
    font-size: 20px;
    line-height: 26px;
  }
}
.benefit__element p {
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}
@media (max-width: 960px) {
  .benefit__element p {
    font-size: 16px;
    line-height: 20px;
  }
}