@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.button {
    max-width: 227px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    background: $white;
    position: relative;
    font-family: 'Geometria', sans-serif;
    border-radius: 2px;
    letter-spacing: 0.04em;
    z-index: 2;

    @include portrait {
        font-size: 13px;
        height: 46px;
    }

    &:before {
        content: '';
        position: absolute;
        width: 227px;
        height: 56px;
        border: 4px solid $white;
        right: -14px;
        bottom: -14px;
        @include transition(.15s ease-in-out);
        pointer-events: none;
        border-radius: 2px;
        z-index: -1;

        @include portrait {
            height: 46px;
        }
    }

    &:hover:before {
        right: 0;
        bottom: 0;
    }

    &.dark {
        background: $black;
        color: $white;
    }
    
    &.dark:before {
        border-color: $black;
    }

    &.blue {
        background: $blue;
        color: $white;
    }
    
    &.blue:before {
        border-color: $blue;
    }
}