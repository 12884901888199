@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.footer {
    padding: 120px 0 0 0;

    @include portrait {
        padding-top: 100px;
    }

    @include mobile {
        padding-top: 100px;
    }

    .container {
        max-width: 780px;
        flex-direction: column;

        @include landscape {
            max-width: 820px;
        }

        @include portrait {
            max-width: 640px;
        }

        @include portrait {
            max-width: 345px;
        }
    }

    h2 {
        margin-bottom: 50px;
    }

    &__wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 130px;

        @include portrait {
            margin-bottom: 85px;
        }
        @include mobile {
            flex-direction: column;
        }
    }

    .icon {
        fill: $blue;
        margin-right: 13px;
        height: 32px;

        @include mobile {
            margin-bottom: 7px;
            margin-right: 0;
        }

        &-tg {
            width: 39px;
        }

        &-phone {
            width: 23px;
        }

        &-mail {
            width: 40px;
        }
    }

    &__element {
        display: flex;
        align-items: center;
        color: $blue;
        @include transition(.15s ease-in-out);

        @include mobile {
            flex-direction: column;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:hover {
            opacity: 0.8;
        }
    }

    &__politics {
        background: $blue;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            font-size: 14px;
            color: $white;
            font-weight: 400;
            border-bottom: 1px solid transparent;
            @include transition(.15s ease-in-out);

            @include mobile {
                font-size: 20px;
            }

            &:hover {
                border-bottom-color: $white;
            }
        }
    }
}