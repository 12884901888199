.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 30;
  visibility: hidden;
  opacity: 0;
  transition: 0.15s ease-in-out;
}
.modal.show {
  visibility: visible;
  opacity: 1;
  pointer-events: unset;
  transition: 0.15s ease-in-out;
}
.modal .icon {
  stroke: #272F35;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 20px;
}
.modal__overlay {
  background: rgba(2, 157, 247, 0.5);
  width: 100vw;
  height: 100vh;
}
.modal__window {
  padding: 85px 130px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(calc(-50% + 0.5px), calc(-50% + 0.5px));
  max-width: 920px;
  width: 100%;
}
@media (max-width: 768px) {
  .modal__window {
    padding: 40px 10px;
    max-width: 630px;
  }
}
.modal h2 {
  color: #272F35;
  margin-bottom: 23px;
}
.modal h3 {
  width: 100%;
  text-align: center;
  color: #029DF7;
  font-weight: 18px;
  line-height: 23px;
}
.modal__form {
  max-width: 540px;
  margin: 60px auto 0 auto;
  width: 100%;
  display: block;
}
@media (max-width: 768px) {
  .modal__form {
    max-width: 410px;
  }
}
.modal__form input {
  margin-bottom: 15px;
  background: #f5f5f5;
}
.modal__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}
.modal .button {
  max-width: 167px;
  margin-right: 14px;
}
@media (max-width: 768px) {
  .modal .button {
    width: 117px;
  }
}
.modal .button:before {
  width: 167px;
}
@media (max-width: 768px) {
  .modal .button:before {
    width: 117px;
  }
}
.modal__personals {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  max-width: 230px;
}
@media (max-width: 768px) {
  .modal__personals {
    font-size: 12px;
    max-width: 200px;
  }
}
.modal__personals a {
  color: #272F35;
  transition: 0.15s ease-in-out;
  border-bottom: 1px solid #272F35;
}
.modal__personals a:hover {
  border-bottom-color: transparent;
}